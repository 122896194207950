import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '@app/components/button';
import { IconName } from '@app/components/icon';

interface CustomerLinkProps {
  to: string;
  label: string;
  icon: IconName;
}

export const CustomerLink: FC<CustomerLinkProps> = ({ to, label, icon }) => {
  return (
    <NavLink end to={to}>
      {({ isActive }) => (
        <Button style={{ width: '100%' }} kind={isActive ? 'whitePrimary' : 'white'} icon={icon}>
          {label}
        </Button>
      )}
    </NavLink>
  );
};

import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const ProtectedCustomerNavbar = styled.div`
  display: flex;
  position: relative;
  margin-top: -20px;
  transform: translateY(-50%);
  justify-content: space-between;
  z-index: 1;
`;

export const ProtectedCustomerButtons = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: stretch;
  gap: 20px;

  ${breakpointsMedia.md} {
    width: auto;
  }
`;

export const ProtectedCustomerContent = styled.div`
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
`;

import { Icon } from '@app/components/icon';
import { scaleXIn } from '@app/styles/animations/scale-x-in';
import styled from 'styled-components';

export const CustomerDownloadMenuWrapper = styled.div`
  position: relative;
`;

export const CustomerDownloadDropdown = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;

  transform-origin: top center;
  animation: ${scaleXIn} 0.3s ease-in-out;
`;

interface CustomerDownloadArrowProps {
  $isOpen: boolean;
}
export const CustomerDownloadArrow = styled(Icon).attrs(() => ({ size: 15 }))<CustomerDownloadArrowProps>`
  transition: transform 0.2s ease-in-out;
  transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

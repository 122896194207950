import { useEffect } from 'react';
import { useParams } from 'react-router';
import { CustomerSigninPage } from '@app/customer/pages/customer-signin-page';
import { ProtectedCustomerButtons, ProtectedCustomerContent, ProtectedCustomerNavbar } from './protected-customer.styles';
import { RouterBoundary } from '@app/components/boundary';
import { getErrorCode } from '@app/utils/apollo-errors.util';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import { useGalleryIdBySlug } from '@app/feautres/gallery/hooks/use-gallery-id-by-slug';
import { GalleryIdProvider } from '@app/feautres/gallery/providers/gallery-id-provider';
import { CustomerHero } from './hero';
import { Responsive } from '@app/components/responsive';
import { CustomerLink } from './link';
import { CustomerDownloadMenu } from './download-menu';

const galleryDocument = graphql(`
  query customerGallery($galleryId: UUID!) {
    gallery(galleryId: $galleryId) {
      id
      name
      slug
      endsAt
    }
  }
`);

export const ProtectedCustomer = () => {
  const gallerySlug = useParams().gallerySlug as string;
  const galleryId = useGalleryIdBySlug(gallerySlug);

  const { data, error } = useSuspenseQuery(galleryDocument, {
    variables: { galleryId },
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (!data) return;
    document.title = data.gallery.name;
  }, [data]);

  if (['UNAUTHORIZED', 'FORBIDDEN'].includes(getErrorCode(error))) return <CustomerSigninPage />;

  if (!data) throw error;
  return (
    <GalleryIdProvider galleryId={galleryId}>
      <CustomerHero title={data.gallery.name} />
      <ProtectedCustomerContent>
        <ProtectedCustomerNavbar>
          <ProtectedCustomerButtons>
            <CustomerLink to="" label="Gallery" icon="gallery" />
            <CustomerLink to="stats" label="Statistics" icon="stats" />
          </ProtectedCustomerButtons>
          <Responsive target="desktop">
            <CustomerDownloadMenu />
          </Responsive>
        </ProtectedCustomerNavbar>
        <RouterBoundary />
      </ProtectedCustomerContent>
    </GalleryIdProvider>
  );
};

import { FC } from 'react';
import { CustomerHeroContent, CustomerHeroLogo, CustomerHeroTitle, CustomerHeroWrapper } from './customer-hero.styles';
import logoWhiteImg from '@app/assets/logo-white.svg';

interface CustomerHeroProps {
  title: string;
}

export const CustomerHero: FC<CustomerHeroProps> = ({ title }) => {
  return (
    <CustomerHeroWrapper>
      <CustomerHeroContent>
        <CustomerHeroLogo src={logoWhiteImg} alt="logo" />
        <CustomerHeroTitle>{title}</CustomerHeroTitle>
      </CustomerHeroContent>
    </CustomerHeroWrapper>
  );
};

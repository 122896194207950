import styled from 'styled-components';
import { breakpointsMedia } from '@app/styles/breakpoints';

export const CustomerSigninPageWrapper = styled.div`
  min-height: 100vh;
  min-height: 100dvh;
  padding: 15vh 20px;
  background: ${({ theme }) => theme.colors.primary};
`;

export const CustomerSigninPageBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 60px 20px;
  margin: 0 auto;
  background: ${({ theme }) => theme.colors.white};
  width: 570px;
  max-width: 100%;
  box-sizing: border-box;

  ${breakpointsMedia.md} {
    padding: 60px;
  }
`;

import styled from 'styled-components';

export const SwitchWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;

  cursor: pointer;
  & * {
    cursor: pointer;
  }
`;

export const SwitchButton = styled.div`
  position: relative;
  outline: 0;
  display: block;
  width: 50px;
  height: 25px;

  user-select: none;
  background: #dedede;
  border-radius: 30px;
  padding: 2px;
  transition: all 0.4s ease;

  &::after {
    position: relative;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    left: 0;
    border-radius: 50%;
    background: #fff;
    transition: all 0.2s ease;
  }
`;

export const SwitchLabel = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  user-select: none;
`;

export const SwitchInput = styled.input`
  display: none;
  background: none;

  &:checked + ${SwitchWrapper} ${SwitchButton}::after {
    left: 50%;
  }

  &:checked + ${SwitchWrapper} ${SwitchButton} {
    background: #9fd6ae;
  }

  & + ${SwitchWrapper} ${SwitchLabel} {
    opacity: 0.5;
  }
  &:checked + ${SwitchWrapper} ${SwitchLabel} {
    opacity: 1;
  }
`;

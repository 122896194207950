import { FC, useCallback, useTransition } from 'react';
import { useInfiniteScroll } from '@app/hooks/use-infinite-scroll';
import { PageInfo } from '@app/layout/page-info';
import { CapturesView } from '@app/feautres/capture/views/captures';
import { graphql } from '@app/__generated__';
import { useSuspenseQuery } from '@apollo/client';
import { CapturesFiltersInput } from '@app/__generated__/graphql';
import { BaseCaptureButtons } from '@app/feautres/capture/components/base-capture-buttons/base-capture-buttons';

const capturesDocument = graphql(`
  query customerCaptures($limit: Int!, $cursor: String, $filters: CapturesFiltersInput) {
    captures: seekCaptures(limit: $limit, cursor: $cursor, filters: $filters) {
      cursor
      items {
        id
        shortId
        uploadFilename
        status
        file {
          path
        }
        preview {
          path
        }
        thumbnail {
          path
        }
      }
    }
  }
`);

interface CustomerInfiniteCapturesProps {
  filters?: CapturesFiltersInput;
}

export const CustomerInfiniteCaptures: FC<CustomerInfiniteCapturesProps> = ({ filters }) => {
  const { data, fetchMore } = useSuspenseQuery(capturesDocument, {
    variables: {
      limit: 20,
      filters,
    },
    fetchPolicy: 'cache-first',
  });

  const [isPending, startTranstion] = useTransition();

  const cursor = data.captures.cursor;
  const loadMoreIfExists = useCallback(() => {
    if (!cursor || isPending) return;
    startTranstion(() => {
      fetchMore({ variables: { cursor } });
    });
  }, [cursor, fetchMore, startTranstion, isPending]);

  useInfiniteScroll(loadMoreIfExists, isPending);

  const handleSelectedChange = useCallback(
    (index: number) => {
      const leftCount = data.captures.items.length - index;
      if (leftCount < 10) loadMoreIfExists();
    },
    [data.captures.items.length, loadMoreIfExists],
  );

  if (data.captures.items.length === 0) return <PageInfo>Nice to see you, but you are too early...</PageInfo>;

  return (
    <CapturesView
      buttons={(capture) => <BaseCaptureButtons capture={capture} />}
      captures={data.captures.items}
      onSelectedChange={handleSelectedChange}
    />
  );
};

import { Button } from '@app/components/button';
import { DownloadCapturesZip } from '@app/feautres/capture/containers/download-captures-zip';
import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { useOutsideClick } from '@app/hooks/use-outside-click';
import { formatBytes } from '@app/utils/format.util';
import { Suspense, useState } from 'react';
import { CustomerDownloadArrow, CustomerDownloadDropdown, CustomerDownloadMenuWrapper } from './customer-download-menu.styles';

export const CustomerDownloadMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const galleryId = useGalleryId();

  const ref = useOutsideClick(() => setIsOpen(false));

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <CustomerDownloadMenuWrapper ref={ref}>
      <Button kind="secondary" icon="download" onClick={handleClick}>
        Download zip
        <CustomerDownloadArrow name="arrowRightSm" $isOpen={isOpen} />
      </Button>
      {isOpen && (
        <CustomerDownloadDropdown>
          <Suspense fallback={<Button kind="primary" size="small" isLoading children="." />}>
            <DownloadCapturesZip filename={'all'} filters={{ galleryId }}>
              {({ download, size, loading }) => (
                <Button kind="primary" size="small" isLoading={loading} onClick={() => download()}>
                  all<b> {formatBytes(size)}</b>
                </Button>
              )}
            </DownloadCapturesZip>
            <DownloadCapturesZip filename={'agreement'} filters={{ galleryId, metadata: { marketingConsent: true } }}>
              {({ download, size, loading }) => (
                <Button kind="primary" size="small" isLoading={loading} onClick={() => download()}>
                  marketing <b>{formatBytes(size)}</b>
                </Button>
              )}
            </DownloadCapturesZip>
          </Suspense>
        </CustomerDownloadDropdown>
      )}
    </CustomerDownloadMenuWrapper>
  );
};

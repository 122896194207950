import { breakpointsMedia } from '@app/styles/breakpoints';
import styled from 'styled-components';

export const CustomerHeroWrapper = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding: 12px 0;
`;

export const CustomerHeroContent = styled.div`
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
`;

export const CustomerHeroLogo = styled.img`
  width: 70px;

  ${breakpointsMedia.md} {
    width: 100px;
  }
`;

export const CustomerHeroTitle = styled.div`
  padding: 25px 0;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.base};
  text-align: center;

  ${breakpointsMedia.md} {
    padding: 60px 0;
    font-size: ${({ theme }) => theme.fontSizes.xxl};
    text-align: left;
  }
`;

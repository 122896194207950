import { useState } from 'react';
import { useGalleryId } from '@app/feautres/gallery/contexts/gallery-id.context';
import { CaptureStatus } from '@app/__generated__/graphql';
import { Switch } from '@app/components/switch';
import { Flex } from '@app/layout/flex';
import { CustomerInfiniteCaptures } from './customer-infinite-captures';
import { Boundary } from '@app/components/boundary';
import { LayoutItem } from '@app/layout/layout-item';
import { useQuery } from '@apollo/client';
import { graphql } from '@app/__generated__';

const capturesCountDocument = graphql(`
  query capturesCount($filters: CapturesFiltersInput!) {
    count: capturesSize(filters: $filters)
  }
`);

export const CustomerCapturesPage = () => {
  const galleryId = useGalleryId();

  const [isMarketing, setIsMarketing] = useState(false);

  const { data } = useQuery(capturesCountDocument, {
    variables: { filters: { galleryId, metadata: { marketingConsent: true } } },
    fetchPolicy: 'cache-first',
  });

  const hasMarketing = !!data?.count;

  return (
    <Flex>
      {hasMarketing && (
        <LayoutItem align="center">
          <Switch checked={isMarketing} onChange={setIsMarketing}>
            Only marketing
          </Switch>
        </LayoutItem>
      )}
      <Boundary>
        <CustomerInfiniteCaptures
          filters={{ galleryId, status: CaptureStatus.Processed, metadata: { marketingConsent: isMarketing ? true : undefined } }}
        />
      </Boundary>
    </Flex>
  );
};

import { useEffect } from 'react';

export const useInfiniteScroll = (onShouldLoad: () => void, isPending?: boolean) => {
  useEffect(() => {
    if (isPending) return;

    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop + 500 < document.documentElement.offsetHeight) return;
      onShouldLoad();
      window.removeEventListener('scroll', handleScroll);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onShouldLoad, isPending]);
};

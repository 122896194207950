import { createBrowserRouter, Navigate } from 'react-router-dom';
import { ProtectedCustomer } from './protected-customer';
import { CustomerCapturesPage } from '../pages/customer-captures-page';
import { CustomerStatsPage } from '../pages/customer-stats-page';
import { Redirect } from '@app/components/redirect';
import { RouterBoundary } from '@app/components/boundary';

export const router = createBrowserRouter(
  [
    {
      element: <RouterBoundary />,
      children: [
        {
          path: '/:gallerySlug',
          element: <ProtectedCustomer />,
          children: [
            {
              path: '',
              element: <CustomerCapturesPage />,
            },
            {
              path: 'stats',
              element: <CustomerStatsPage />,
            },
            {
              path: '*?',
              element: <Navigate to="" />,
            },
          ],
        },
      ],
    },
    {
      path: '*?',
      element: <Redirect url="https://picsane.tech/" />,
    },
  ],
  { basename: import.meta.env.DEV ? '/customer' : '/' },
);

import { ApolloClient, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { httpLink } from './links/http-link';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        seekCaptures: {
          keyArgs: ['galleryId', 'filters'],
          merge: (existing = { items: [] }, { cursor, items }) => ({ cursor, items: [...existing.items, ...items] }),
        },
      },
    },
  },
});

const authLink = setContext(async (_, { headers }) => {
  const galleryToken = localStorage.getItem('galleryToken');
  return { headers: { ...headers, authorization: `Bearer ${galleryToken}` } };
});

export const customerClient = new ApolloClient({
  link: from([authLink, httpLink]),
  cache,
  defaultOptions: { watchQuery: { fetchPolicy: 'cache-and-network' }, react: { suspense: { autoDisposeTimeoutMs: 2000 } } },
});

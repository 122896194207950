import { FormEvent, useState } from 'react';
import { CustomerSigninPageBox, CustomerSigninPageWrapper } from './customer-signin-page.styles';
import { Button } from '@app/components/button';
import { getErrorCode, getErrorHandler } from '@app/utils/apollo-errors.util';
import { Text } from '@app/layout/text';
import { Input } from '@app/components/input';
import { FormField } from '@app/layout/form-field';
import { graphql } from '@app/__generated__';
import { useMutation } from '@apollo/client';
import { CustomerGalleryDocument } from '@app/__generated__/graphql';
import { useParams } from 'react-router-dom';
import { useGalleryIdBySlug } from '@app/feautres/gallery/hooks/use-gallery-id-by-slug';

const customerSigninDocument = graphql(`
  mutation customerSignIn($galleryId: UUID!, $password: String!) {
    customerSignin(galleryId: $galleryId, password: $password) {
      customerToken
    }
  }
`);

export const CustomerSigninPage = () => {
  const [password, setPassword] = useState('');

  const gallerySlug = useParams().gallerySlug as string;
  const galleryId = useGalleryIdBySlug(gallerySlug);

  const [signIn, { loading, error }] = useMutation(customerSigninDocument, {
    variables: { galleryId, password },
    update: (_, { data }) => {
      if (!data) return;
      localStorage.setItem('galleryToken', data.customerSignin.customerToken);
    },
    refetchQueries: [CustomerGalleryDocument],
    awaitRefetchQueries: true,
    onError: getErrorHandler({ INVALID_CREDENTIALS: () => {} }),
  });

  const isInvalidPassword = getErrorCode(error) === 'INVALID_CREDENTIALS';

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (password.length > 0) signIn();
  };

  return (
    <CustomerSigninPageWrapper>
      <form onSubmit={handleSubmit}>
        <CustomerSigninPageBox>
          <Text size="lg">Sign in</Text>
          <FormField error={{ message: isInvalidPassword ? 'Invalid password' : undefined }}>
            <Input
              type="password"
              placeholder="password"
              icon="padlock"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              isError={isInvalidPassword}
            />
          </FormField>

          <Button type="submit" size="large" isLoading={loading} icon="tick">
            SIGN IN
          </Button>
        </CustomerSigninPageBox>
      </form>
    </CustomerSigninPageWrapper>
  );
};

import { Boundary } from '@app/components/boundary';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { theme } from '@app/styles/theme';
import { customerClient } from '@app/apollo/customer-client';
import { GlobalStyles } from '@app/styles/global';
import { ToastsContainer } from '@app/components/toasts-container';
import { RouterProvider } from 'react-router-dom';
import { router } from './navigation/router';

export const App = () => {
  return (
    <Boundary>
      <ApolloProvider client={customerClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ToastsContainer />
          <RouterProvider router={router} />
        </ThemeProvider>
      </ApolloProvider>
    </Boundary>
  );
};

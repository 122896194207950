import { FC, useId } from 'react';
import { SwitchButton, SwitchInput, SwitchLabel, SwitchWrapper } from './switch.styles';

interface SwitchProps {
  children: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const Switch: FC<SwitchProps> = ({ children, checked, onChange }) => {
  const id = useId();
  return (
    <>
      <SwitchInput id={id} type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
      <SwitchWrapper htmlFor={id}>
        <SwitchButton />
        <SwitchLabel>{children}</SwitchLabel>
      </SwitchWrapper>
    </>
  );
};
